.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.headerInfoInner {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.headerInto {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.headerInfoLeft{
    transform: translateY(-150%);
    -webkit-transition-duration: 0.1s;
    -moz-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    animation: slideOnLoad 0.7s 1;
    -webkit-animation: slideOnLoad 0.7s 1;
    animation-fill-mode: forwards;
}

@keyframes slideOnLoad
{
    0% {transform: translateY(-150%); opacity: 0;}
    50% { opacity: 0.5;}
    100% {transform: translateY(0%); opacity: 1;}
}
@-webkit-keyframes slideOnLoad
{
    0% {transform: translateY(-150%); opacity: 0;}
    50% { opacity: 0.5;}
    100% {transform: translateY(0%); opacity: 1;}
}

.arrowPictureElement{
    margin-left: 30px;
}

.headerInfoRight {
    z-index: 1;
}

.bigBlueBackground {
    position: absolute;
    height: 959px;
    width: 1245px;
    right: 0px;
    top: 0px;
    background-image: image-set('/images/header-background.svg' 1x);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.smallColoredText {
    color: #1E96DC;
    font-weight: bold;
    font-family: 'Baloo Chettan 2 Bold', sans-serif;
    font-size: 18px;
}

.mainText {
    font-family: 'Baloo Chettan 2 Bold', sans-serif;
    color: #0F3371;
    font-size: 45px;
    padding-right: 30px;
    line-height: 60px;
    font-weight: bold;
}

.mainText span {
    color: #1E96DC;
    position: relative;
}

.mainText span::after {
    background-image: url('/images/header-text-underline-arrow.svg');
    background-repeat: no-repeat;
    height: 8px;
    position: absolute;
    width: 313px;
    content: '';
    bottom: 0px;
    right: 0px;
}

.mainTextDescription {
    margin-top: 20px;
    color: #405578;
    font-family: 'Baloo Chettan 2 Regular', sans-serif;
    font-size: 20px;
    line-height: 30px;
    width: 520px;
}

.learnMoreBtn {
    font-family: 'Baloo Chettan 2 Bold', sanse-serif;
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 40px;
    background: #0F3371;
    border-radius: 10px;
    border: #0F3371 solid 1px;
    padding: 5px 30px;
    text-transform: capitalize;
}

.learnMoreBtn:hover {
    background: #123c85;
}

.getStartedContainer {
    width: 100%;
    text-align: center;
    padding: 80px;
}


@media only screen and (min-width: 2300px) {
    .bigBlueBackground{
        width: 1409px;
        height: 1100px;
    }

    .headerInto{
        margin-bottom: 100px !important;
    }
}

@media only screen and (min-width: 2500px) {
    .bigBlueBackground{
        width: 1609px;
        height: 1300px;
    }

    .headerInto{
        margin-bottom: 200px !important;
    }
}

@media only screen and (min-width: 2800px) {
    .bigBlueBackground{
        width: 1650px;
        height: 1300px;
    }

    .headerInto{
        margin-bottom: 200px !important;
    }
}

@media only screen and (min-width: 3200px) {
    .bigBlueBackground{
        width: 1909px;
        height: 1500px;
    }

    .headerInto{
        margin-bottom: 200px !important;
    }
}


@media only screen and (max-width: 2000px) {
    .bigBlueBackground{
        width: 1159px;
        height: 1040px;
    }
}

@media only screen and (max-width: 1750px) {
    .bigBlueBackground{
        width: 1109px;
    }
}

@media only screen and (max-width: 1450px) {
    .bigBlueBackground{
        width: 909px;
    }

    .headerInfoRight img{
        width: 609px;
        height: 552px;
    }

    .headerInto{
        margin-bottom: 100px;
    }
}

/* tablets */
@media only screen and (max-width: 1260px) {
    .bigBlueBackground{
        width: 709px;
        height: 709px;
    }

    .headerInfoRight img{
        width: 509px;
        height: 472px;
    }

    .headerInto{
        margin-bottom: 100px;
    }

    .headerInto h1{
        font-size: 40px;
        line-height: 50px;
    }

    .headerInto h2{
        font-size: 20px;
    }
    .headerInto h3{
        font-size: 20px;
    }

    .headerInfoLeft{
        width: 48%;
        padding: 30px
    }

    .mainTextDescription{
        width: 100%;
    }

    .learnMoreBtn{
        font-size: 20px;
    }
}

@media only screen and (max-width: 1000px) {
    .bigBlueBackground {
        width: 649px;
        height: 649px;
    }
}


/* mobiles */
@media only screen and (max-width: 900px) {
    .arrowPictureElement{
        width: 130px;
        height: 100px;
    }

    .bigBlueBackground{
        width: 130px;
        height: 200px;
    }

    .headerInfoRight img{
        /*width: 609px;*/
        /*height: 409px;*/
        display: none;
    }

    .getStartedContainer {
        width: 100%;
        text-align: center;
        margin-top:30px;
        padding:15px;
    }

    .getStartedContainer h2{
        font-size: 2.5rem;
    }

    .headerInfoInner{
        flex-direction: column-reverse;
        justify-content: left;
        align-items: flex-start;
        width: 100%;
    }

    .headerInto{
        margin-bottom: 10px;
    }

    .headerInto h1{
        font-size: 35px;
        width: 100%;
        line-height: 40px;
    }

    .headerInto h2{
        font-size: 20px;
    }
    .headerInto h3{
        font-size: 20px;
        line-height: 40px;
    }

    .headerInfoLeft{
        width: 100%;
        padding: 15px
    }

    .mainTextDescription{
        width: 100%;
    }

    .learnMoreBtn{
        font-size: 20px;
    }
}
