.container{
    display: flex;
    width:100%;
    height: 350px;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    background: transparent !important;
    z-index: 1;
}

.innerContainer{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    color: #555555;
    align-items: center;
}

.primaryText{
    font-size: 3rem;
    color: #0F3371;
    font-weight: bold;
    text-transform: capitalize;
    line-height: initial;
    position: relative;
    font-family: 'Baloo Chettan 2 Bold', sans-serif;
    margin-bottom: 30px
}

.primaryText::after{
    background-image: url('/images/header-underline.svg');
    background-repeat: no-repeat;
    height: 8px;
    position: absolute;
    width: 188px;
    content: '';
    bottom: -10px;
    left: 0px;
}

.contentText{
    font-size: 20px;
    line-height: 30px;
    width: 600px
}

.text{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.image{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: space-between;
}

/* tablets */
@media only screen and (max-width: 1260px) {
    .image img{
        width: 259px;
        height: 170px;
    }

    .contentText{
        width:100%;
    }

    .primaryText{
        font-size: 2rem;
    }

    .innerContainer{
        justify-content: space-evenly;
    }

    .container{
        height: auto;
    }

    .text, .image{
        flex: initial;
    }
}

/* mobiles */
@media only screen and (max-width: 900px) {
    .image img{
        padding: 10px 0 10px 0;
        width: 300px;
        height: 210px;
    }

    .contentText{
        width:100%;
    }

    .primaryText{
        font-size: 2rem;
    }

    .innerContainer{
        justify-content: space-evenly;
        flex-direction: column-reverse !important;
    }

    .container{
        height: auto;
    }

    .text, .image{
        flex: initial;
        max-width:100%;
    }

    .text{
        padding:0 15px;
    }
}
